const ENVIRONMENT_CONFIG = {
  arch: {
    playerPositionsForHide: [
      { row: 7, column: 2 },
      { row: 7, column: 3 },
      { row: 7, column: 4 },
      { row: 7, column: 5 },
    ]
  },
}

export { ENVIRONMENT_CONFIG };
